import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import star from "../images/1.ico";
import "../App.css";

export default function Header() {
 
  return (
    <header className="header d-flex align-items-center fixed-top">
      <div className="container-fluid container-xl position-relative d-flex align-items-center">
        <div className="logo d-flex align-items-center me-auto">
          <Link to={`/`}>
            <img src={logo} className="gamehubs" alt="Game Hubs Logo" />
          </Link>
          <Link to={`/`} className="hot_">
            <h1 className="btn-getstarted">
              <img src={star} className="hot" alt="Hot" />
              Hot
            </h1>
          </Link>
        </div>
      </div>
    </header>
  );
}
