import React from 'react';
import "../App.css";

const SkeletonLoader = () => {
  return (
    <p className="skeleton-loader skeleton-ad p_tg text-center text-16">Loading...</p>
  );
};

export default SkeletonLoader;
