import React, { useEffect, useState } from 'react';
import "../App.css";

const AdsenseContent = ({ adUnit, name }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const handleScriptLoad = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7182611503421709";
    script.async = true;
    script.crossOrigin = "anonymous";
    script.onload = handleScriptLoad;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (isLoaded) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, [isLoaded]);

  return (
    <div style={{ textAlign: 'center' }}>
      {isLoaded ? (
        <ins
          className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client={adUnit}
          data-ad-slot={name}
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      ) : (
        <div>Loading Ad...</div>
      )}
    </div>
  );
};

export default AdsenseContent;
