import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

export default function Term() {
  return (
    <main className="main backShare">
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <br />
      <br />
      <br />
      <div className="container container_bg">
        <div className="row">
          <div className="col-lg-12">
            <h2>Terms Of Use:</h2>
            <h4>1. ABOUT US</h4>
            <p className="p_tag_2">
              1.1. Welcome to GameJuction. See here for more GameJuction contact
              information. These Terms of Use apply to your use of GameJuction’
              web and mobile games and other offered content, websites and any
              related services (including servers, software and infrastructure)
              (hereinafter the “Service”). Use of the Service is also governed
              by SPIL’s Privacy Policy or the Mobile Apps Privacy Policy, which
              are incorporated herein by reference and can be found here for web
              games and here for mobile games. GameJuction may add any sites to,
              or remove any sites from this list at its own discretion at any
              time.
            </p>
            <p className="p_tag_2">
              1.2. Please read these Terms of Use carefully before you use the
              Service. By installing, using or otherwise accessing the Service
              you agree with the applicability and content of these Terms of Use
              as well as the applicable privacy policy. Certain parts of the
              Service, such as webpages or portals where you can download games
              or pay for in-game credits, may include additional terms and
              conditions from us or our suppliers, licensors or other third
              parties, that supplement these Terms of Use. By using those
              services, you agree to comply with such additional terms and
              conditions. If you do not agree to the Terms of Use, the
              applicable Privacy Policy or additional terms and conditions, do
              not to use the Service.
            </p>
            <h4>2. REGISTRATION AND ACCOUNT</h4>
            <p className="p_tag_2">
              2.1. Subject to your agreement and continuing compliance with
              these Terms of Use and any other relevant policies and terms and
              conditions, GameJuction grants you a non-exclusive,
              non-transferable, non-sublicensable, revocable limited license
              subject to the limitations below to access and use the Service for
              your own non-commercial entertainment purposes. You agree not to
              use the Service for any other purpose. GameJuction offers a great
              variety of games on its portals and on other platforms such as
              application stores. These games are free to play unless indicated
              otherwise. However, you are solely responsible for any costs you
              may incur using the Service through any wireless or other
              communication service. In certain games you may purchase virtual
              items (such as a virtual game element or virtual coins) or a
              subscription to download a game. For the processing of any
              payments on the GameJuction Sites or any other platform,
              GameJuction Games may use third party payment providers as set out
              in Section 6.
            </p>
            <p className="p_tag_2">
              2.2. You may create an account on the Service (“Account”). By
              creating an Account, you confirm that you are 16 years of age or
              older.
            </p>
            <p className="p_tag_2">
              2.3. A username is a unique identifier either selected by you or
              provided by us, and is used to identify you on the Service. You
              may only have one account on the GameJuction Sites and one account
              per game. GameJuction reserves the right to delete any of your
              accounts, in case you have multiple accounts. You may not sell,
              rent or give away your Account, create an Account using a false
              identity or information, or on behalf of someone other than
              yourself. Please notify us immediately if your Account has been
              unauthorized accessed and modify your login details immediately.
              You are solely responsible for maintaining the confidentiality of
              the login details, and for any purchases made through your
              Account, whether or not authorized by you. If you provided us with
              contact information, you shall keep it accurate and up-to-date.
              You shall only use your Account for non-commercial purposes.
            </p>
            <p className="p_tag_2">
              2.4. GameJuction reserves the right to terminate and permanently
              deactivate your Account if it has been inactive for 180 days. In
              case of deactivation, we have no obligation to retain or provide
              any data or content related to, or refund any payments made
              through your (former) Account, and we may allow another user to
              register and use your former username. We also have no obligation
              to remove any public data, content, or other information that you
              uploaded or posted on the Service.
            </p>
            <p className="p_tag_2">
              2.5. Without limiting any other remedies, GameJuction may limit,
              suspend terminate, modify, or delete accounts or access to the
              Service or portions thereof if you are, or GameJuction suspects
              that you are failing to comply with the Terms of Use or for any
              actual or suspected illegal or improper use of the Service,
              without notice to you. GameJuction is under no obligation to
              compensate you for any losses or results related to this,
              including but not limited to any benefits, privileges or virtual
              items associated with your use of the Service.
            </p>
            <h4>3. USER GENERATED CONTENT</h4>
            <p className="p_tag_2">
              3.1. The Service may contain content from players (“User Generated
              Content”), such as messages, wall posts, opinions comments,
              pictures, or graphics. We are not actively involved in the
              creation nor publication of such User Generated Content, and we do
              not endorse any User Generated Content, nor do we warrant that
              such content does not infringe any third party rights or is not
              (otherwise) unlawful. Your use of the Service is at your own risk.
              By using the Service, you may be exposed to User Generated Content
              that is offensive, indecent or otherwise not in line with your
              expectations. You bear all risks associated with the use of any
              User Generated Content available in connection with the Service.
              GameJuction is not responsible nor liable for any User Generated
              Content.
            </p>
            <p className="p_tag_2">
              3.2. If you submit any User Generated Content yourself, you grant
              GameJuction a non-exclusive, irrevocable, perpetual, transferable,
              fully paid-up, royalty-free, worldwide license (including the
              right to sublicense and assign to third parties) to use, publish,
              reproduce, distribute, adjust, prepare derivative works of, or
              otherwise exploit in any manner whatsoever such submitted User
              Generated Content, or any portion thereof, by any and all means
              and in all forms of media, now known or hereafter developed. To
              the extent permitted by applicable laws, you hereby waive any
              moral rights or rights of publicity or privacy you may have
              regarding such User Generated Content.
            </p>
            <p className="p_tag_2">
              3.3. Without prejudice to 3.2, you will retain possible ownership
              in your User Generated Content. We reserve the right to monitor
              any User Generated Content and your activities in connection with
              the Service, but cannot monitor or prescreen all of the User
              Generated Content and do not attempt to do so. We have the right,
              but not the obligation, to edit, refuse to post, or remove any
              User Generated Content posted on the Service at our own
              discretion, for any reason whatsoever at any time.
            </p>
            <p className="p_tag_2">
              3.4. If you submit any User Generated Content to the Service, you:
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> acknowledge
              and agree that every user of the Service may have access to such
              User Generated Content, and that GameJuction has no control over
              that information or what users do with it;
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> warrant that
              you are authorized to do so and to grant GameJuction the license
              referred to in 3.2, and that such content does not infringe any
              third party rights, including but not limited to any intellectual
              property rights, and does not violate any applicable law. You
              indemnify GameJuction against any third party (among which any
              authorities) claims, related costs and damages in this respect.
            </p>
            <p className="p_tag_2">
              3.5. Upon first request, you will cooperate with GameJuction to
              investigate any suspected unlawful, fraudulent or improper User
              Generated Content (whether from yourself or another player) or
              other activities on the Service. You indemnify GameJuction and its
              officers, directors, agents, subsidiaries, joint ventures and
              employees) against any claims, related costs and damages arising
              out of or in any way connected with any disputes with Service
              users or other third parties that may arise on, or in connection
              with the Service.
            </p>
            <h4>4. ADVERTISEMENTS AND THIRD PARTY CONTENT</h4>
            <p className="p_tag_2">
              4.1. When providing you with our games on the Service we may
              display advertising before, in, after or around such games. Any
              dealings you engage in with any advertisers via our Service are
              between you and the advertiser, and subject to additional terms
              and conditions from such advertiser (if any). GameJuction is not
              liable for any loss or claim you may have against any advertiser.
            </p>
            <p className="p_tag_2">
              4.2. The Service may include links to websites of third parties,
              including links provided as automated search results. The
              existence of such links does not mean that we endorse these
              third-party sites or services. You acknowledge and agree that we
              are not responsible or liable for any content or other materials
              on these third-party sites.
            </p>
            <h4>5. CONDUCT ON THE SERVICE</h4>
            <p className="p_tag_2">
              5.1. You may use the Service offered on it only for lawful,
              personal, non-commercial purposes. To the extent any content is
              available for downloading, such content may only be downloaded for
              personal use, and may not be further reproduced or distributed.
              Any download is subject to the rules that accompany that
              particular content.
            </p>

            <p className="p_tag_2">
              {" "}
              5.2. You may not:
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> engage in any
              act that GameJuction deems to be in conflict with the spirit or
              intent of the Service
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> use cheats,
              exploits, automation software, bots, hacks, mods or any
              unauthorized third-party software designed to modify or interfere
              with the Service. <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> use the
              Service in a manner that exceeds the rights granted for your use;{" "}
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> use the
              Service for any data mining, robots, or similar data-gathering and
              -extraction tools on the content; frame any portion of the Service{" "}
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> reproduce,
              copy, adjust, display, modify, translate, port, publish,
              (sub)license, transfer, or otherwise distribute or exploit the
              content available on the Service without our prior written
              consent; <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> reverse
              engineer, decompile, disassemble, decipher or otherwise attempt to
              derive the source code for any underlying software or other
              intellectual property used to provide the Service, or to obtain
              any information from the Service using any method not expressly
              permitted by GameJuction.
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> offer any
              form of advertising or promotion on the Service without our prior
              written consent;
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> provide any
              false personal information, or any information, content, or
              material on account of a third party without permission;
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> transmit,
              directly or indirectly, any unsolicited bulk communications
              (including emails and instant messages) through the Service;
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> use the
              Service in a manner that could damage, disable, or overburden our
              servers or networks;
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> interfere
              with any other party's use and enjoyment of the Service; or
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> circumvent
              any mechanisms included in the Service for preventing the
              unauthorized reproduction or distribution of any content on the
              Service. 5.3. You may not submit or transmit through the Service
              any information, content, or material or otherwise engage in any
              conduct that:
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> violates or
              infringes the rights of others, including, without limitation,
              patent, trademark, trade secret, copyright, publicity, or other
              proprietary rights;
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> is unlawful,
              threatening, abusive, harassing, defamatory, libelous, deceptive,
              fraudulent, invasive of another's privacy, tortuous, contains
              explicit or graphic descriptions or accounts of sexual acts, or is
              pornographic;
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> victimizes,
              harasses, degrades, or intimidates an individual or group of
              individuals on the basis of religion, gender, sexual orientation,
              race, ethnicity, age, or disability;
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> impersonates
              any person, business, or entity, including GameJuction and its
              employees and agents;
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> contains
              viruses or any other computer code, files, or programs that
              interrupt, destroy, or limit the functionality of any software or
              hardware or telecommunications equipment, or that otherwise permit
              the unauthorized use of a computer or computer network;
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> encourages
              conduct that would constitute a criminal offense or give rise to
              civil liability; or
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> otherwise
              violates these Terms of Use, guidelines, or any policy posted on
              the Service.
            </p>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
