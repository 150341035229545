import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="header_2">
      <div className="container">
            <nav>
                <a href="/AboutUs" className="AboutUs">About Us</a>
                <a href="/Contact">Contact Us</a>
                <a href="/Privacy">Privacy</a>
                <a href="/Term">Terms Of Use</a>
                <a href="/Subscribe">Subscribe</a>
            </nav>
        </div>
      <div className="container copyright text-center mt-4">
        <p className="copyright"> 
          © <span>Copyright</span>
          <strong className="px-1 sitename">PoppyGame</strong>
          <span>All Rights Reserved</span>
        </p>
      </div>
    </footer>
  );
}

