import React from 'react';
import "../App.css";

const Modal = ({ isOpen, onClose, title, content, qrCode, storeLink }) => {
  if (!isOpen) return null;

  const handleContinue = () => {
    onClose();
    window.open(storeLink, '_blank');
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{title}</h2>
        <p className='pop_p'>{content}</p>
        <img src={qrCode} alt="QR Code" className="modal-qr" />
        <div className="modal-buttons">
          <button className='button_a app_store_botton' onClick={onClose}>Cancel</button>
          <button className='button_b app_store_botton_c' onClick={handleContinue}>Continue</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
