import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

export default function Privacy() {
  return (
    <main className="main backShare">
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <br />
      <br />
      <br />
      <div className="container container_bg">
        <div className="row">
          <div className="col-lg-12">
            <h2>Privacy Policy:</h2>
            <p className="p_tag_2">
              PoppyGame is dedicated to protecting the privacy of our users
              and handling any personal date we obtain from our users with care
              and respect. An overall principle is that we do not collect more
              information than is reasonably necessary to provide you with the
              best user experience.{" "}
            </p>
            <p className="p_tag_2">
              This privacy policy (hereafter referred to as the "Privacy
              Policy") is applicable where PoppyGameacts as a data controller
              with respect to your data. This is the case where we determine the
              purposes and means of the data processing on our websites, which
              includes certain games and other activities that are offered by us
              on or through our websites. Our websites, all the web pages that
              are part of the websites, and such games and activities are
              hereafter collectively referred to as the "Websites."
            </p>
            <h4>1. ABOUT OUR WEBSITES</h4>
            <p className="p_tag_2">
              1.1 Our Websites offer a diverse, and exciting mix of online games
              created by PoppyGame, as well as games created by independent
              developers and PoppyGamepartners. Players can access our
              Websites to play games without registering; Also, visitors to the
              Websites will be able to rate games and browse for the newly
              added, top-rated, and most-popular games. The Websites may include
              automated decision making in the form of behavioral advertising.{" "}
            </p>
            <p className="p_tag_2">
              1.2 Certain games on our Websites are controlled by third parties,
              including third parties located outside of the European Economic
              Area (“EEA”). GameJuction’s own hosting facilities for the
              Websites are located within the EEA. PoppyGameuses commercially
              reasonable efforts to ensure that any data processing that takes
              place on the Websites is in line with the applicable data
              protection laws. Privacy safeguards we take are contractual
              safeguards with third parties and the employment of technical
              privacy solutions, such as monitoring and blocking tools. However,
              as we do not control all games, data may be collected and
              transferred of which we are not aware. If you have concerns about
              data processing in a game, please click
            </p>
            <h4>2. THE INFORMATION WE COLLECT</h4>
            <p className="p_tag_2">
              2.1 PoppyGamecollects information when you use or access our
              Websites. This information relates to how you use our websites
              including with what device, and may include information that you
              submit when creating an account with us or through your social
              network accounts such as Facebook or Google+. We may also collect
              information from ad network providers and other third parties such
              as payment service providers.
            </p>
            <p className="p_tag_2">
              {" "}
              The information we may collect when you use our Websites includes
              (among others) the following:
            </p>
            <p className="p_tag_2">
              <i className="fa fa-circle fafa" aria-hidden="true"></i> Your IP
              address; <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> Demographic
              information (such as age and country);
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> Information
              collected through cookies or similar technologies;
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> When you
              choose to register with us for a better game experience and/or to
              create a public or private profile for participating in our social
              gaming network, we collect information such as username, email
              address, avatar and any information you may choose to provide,
              such as gender. This can be through a social network account;
              information that you provide us when you fill in online forms or
              surveys on our Websites;
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> Other
              information that we receive from you, e.g. your e-mails, chats or
              other communications and information exchanged with us on our
              social media channels;
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> How you use
              our Websites, including when you play our games and interact with
              our advertising network providers;
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> Transaction
              information of payments made;
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> User agent of
              device;
            </p>
            <p className="p_tag_2">IP address, Cookies, and Web Beacons</p>
            <p className="p_tag_2">
              2.2 When you visit our Websites, our servers automatically save
              your computer's IP address via our analytics tracking system. IP
              addresses will be collected for the purpose of analytical tracking
              and improving the Websites, along with geographical location,
              browser type and version, operating system, referral source,
              length of visit, page views and website navigation paths, as well
              as information about the timing, frequency and pattern of your use
              of the Websites. If you arrive at the Websites via a link from
              another website, the URL of the linking website and the URL of any
              website that you link to next will also be collected for the
              purpose of assessing our main traffic sources, and debugging and
              tracing.{" "}
            </p>
            <p className="p_tag_2">
              2.3 In addition, we store certain information from your browser,
              using “cookies”. A cookie is a piece of data stored on the
              computer and is tied to information about the user. We will ask
              you to consent to the use of our Cookies in accordance with this
              Privacy Policy in case this is required under the applicable data
              protection laws when you first use our Websites. SPIL may use
              cookies, web beacons (web bugs), or similar technologies to
              enhance and personalize your experience on the Websites, including
              the following:{" "}
            </p>
            <p className="p_tag_2">
              {" "}
              <i className="fa fa-circle fafa" aria-hidden="true"></i> to operate
              and improve services and features on the Websites;
              <br />
              <i className="fa fa-circle fafa" aria-hidden="true"></i> to help
              authenticate you when you are on the Websites;
              <br /> <i className="fa fa-circle fafa" aria-hidden="true"></i>to
              remember your preferences and registration information, as
              applicable;
              <br /> <i className="fa fa-circle fafa" aria-hidden="true"></i>to
              present and help measure and research the effectiveness of SPIL's
              offerings, advertisements, and email communications; and
              <br /> <i className="fa fa-circle fafa" aria-hidden="true"></i> to
              customize the content and advertisements provided to you through
              the Websites.
            </p>

            <p className="p_tag_2">
              {" "}
              If you do not want PoppyGameto store any cookies on your
              computer, you can prevent this by changing your browser settings
              to “do not accept cookies”. Your browser manufacturer will provide
              you more details on how this works. However, we do inform you that
              you will not be able to use the Websites including its
              functionalities as intended. If you want to learn more about
              GameJuction’s use of Cookies, please click here for our cookie
              policy.{" "}
            </p>
            <p className="p_tag_2">
              2.4 PoppyGameuses analytics partners in order to help get
              insights into the usage of the Websites and services (“Usage
              Data”). These analytics partners process Usage Data which may
              include among others your IP address, geographical location,
              browser type and version, operating system, length of visit, page
              views and other information about how you use and navigate the
              Websites. This data is used to analyze the use of the Websites and
              to improve the Websites and services. Our analytics partners will
              act as a data processor in relation to the Usage Data. The legal
              basis for the processing of the Usage Data is legitimate interest.{" "}
            </p>
            <p className="p_tag_2">
              2.5 PoppyGamealso uses ad network providers, ad exchanges and
              ad servers (“Advertising Partners”) to help present advertisements
              on the Websites. These Advertising Partners use cookies, web
              beacons, or similar technologies for presenting, better targeting,
              and measuring the effectiveness of their advertisements, using
              data gathered over time and across their networks of web pages to
              determine or predict the characteristics and preferences of their
              audiences (“Advertising Data”). PoppyGameprocesses such
              information in accordance with this Privacy Policy. Our
              Advertising Partners may act as a data processor or data
              controller with regards to the Advertising Data, depending on the
              scope of their services. The Advertising Partners are responsible
              for their compliance with the applicable data protection
              legislation. The use of cookies, web beacons, or similar
              technologies by such third parties as ad network providers is
              subject to their own privacy policies, not this Privacy Policy.
              The legal basis for the processing of the Advertising Data is
              consent.
            </p>
            <p className="p_tag_2">
              {" "}
              2.6 PoppyGameuses Facebook technology to provide certain
              services on the Websites. The Websites may include the “Facebook
              Like Button” which is controlled by Facebook Inc., 1601 S.
              California Ave, Palo Alto, CA 94304 USA (Facebook). In the event
              you click on the Facebook Like Button, Facebook will ask you
              whether you want to log in to Facebook if you are not logged in at
              that moment, or register with Facebook. If you decide to do so,
              your personal data will be transferred to Facebook. In the event
              you are already logged into your Facebook account and you have
              given SPIL your explicit consent to the sharing of your
              information with Facebook, your information is shared with
              Facebook when visiting the Websites or playing games including the
              “Facebook Like Button”. <br />
              The Websites also use the “Facebook Pixel” controlled by Facebook
              in order to show conversions, create “lookalike audiences” and
              provide SPIL with relevant statistics about our Websites in order
              to improve them. When you use our websites and you have given SPIL
              your explicit consent to the sharing of your information with
              Facebook, your information is automatically shared with Facebook.
              <br /> For more information on the “Facebook Like Button” and
              “Facebook Pixel” and sharing and use of your personal data please
              consult Facebook’s Data Policy at:
              <br />
              <a href="https://www.facebook.com/policy.php.">
                https://www.facebook.com/policy.php.
              </a>
            </p>
            <p className="p_tag_2">2.5 PoppyGamealso uses ad network providers, ad exchanges and ad servers (“Advertising Partners”) to help present advertisements on the Websites. These Advertising Partners use cookies, web beacons, or similar technologies for presenting, better targeting, and measuring the effectiveness of their advertisements, using data gathered over time and across their networks of web pages to determine or predict the characteristics and preferences of their audiences (“Advertising Data”). PoppyGameprocesses such information in accordance with this Privacy Policy. Our Advertising Partners may act as a data processor or data controller with regards to the Advertising Data, depending on the scope of their services. The Advertising Partners are responsible for their compliance with the applicable data protection legislation. The use of cookies, web beacons, or similar technologies by such third parties as ad network providers is subject to their own privacy policies, not this Privacy Policy. The legal basis for the processing of the Advertising Data is consent.</p>
            <p className="p_tag_2"> 2.6 PoppyGameuses Facebook technology to provide certain services on the Websites. The Websites may include the “Facebook Like Button” which is controlled by Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304 USA (Facebook). In the event you click on the Facebook Like Button, Facebook will ask you whether you want to log in to Facebook if you are not logged in at that moment, or register with Facebook. If you decide to do so, your personal data will be transferred to Facebook. In the event you are already logged into your Facebook account and you have given SPIL your explicit consent to the sharing of your information with Facebook, your information is shared with Facebook when visiting the Websites or playing games including the “Facebook Like Button”. <br/>The Websites also use the “Facebook Pixel” controlled by Facebook in order to show conversions, create “lookalike audiences” and provide SPIL with relevant statistics about our Websites in order to improve them. When you use our websites and you have given SPIL your explicit consent to the sharing of your information with Facebook, your information is automatically shared with Facebook.<br/> For more information on the “Facebook Like Button” and “Facebook Pixel” and sharing and use of your personal data please consult Facebook’s Data Policy at:<br/>
                <a href="https://www.facebook.com/policy.php.">https://www.facebook.com/policy.php.</a>
            </p>
            <p className="p_tag_2">2.7 For optimizing our services and the Websites, and for the purposes mentioned in this article 2, we are entitled to combine your personal data (including your email address, as applicable) with other information about you collected by or provided to us.
            </p>
            <h4>3. TO WHOM DOES PoppyGamePROVIDE THIS INFORMATION?</h4>
            <p className="p_tag_2">3.1 PoppyGamemay provide information to any party whose services it engages for the purposes of its normal operations (for example, those partners who assist us by providing technical or operational facilities for the Websites and so forth), as mentioned under section 2. We may also supply information to other parties if we are required to do so by law or by a ruling handed down by a court of law. PoppyGamedoes not provide personal details to other parties for commercial, advertising, or marketing purposes, except if you explicitly give us permission to do so, or if doing so is part of a specific program or operation for which you have registered. In such case, you may withdraw your permission at any time.</p>
            <h4>4. WHY DO WE COLLECT INFORMATION?</h4>
            <p className="p_tag_2">4.1 We use personal data and other information to maintain the general and personalized content and functionality of the Websites. In addition, we use personal details to make it possible to accommodate your requests, and so that we are able to provide you with the services, as described herein, when using the Websites and for the purposes set out elsewhere in this Privacy Policy. We may (among others) use the information that we have about you:</p>
            <p className="p_tag_2"><i className="fa fa-circle fafa" aria-hidden="true"></i>  To provide you access to the Websites and associated games and services, and continuously improve their features <br/>
            <i className="fa fa-circle fafa" aria-hidden="true"></i>  To store your Website settings, gaming-history and provide personalized content <br/>
            <i className="fa fa-circle fafa" aria-hidden="true"></i>  To provide customer support services <br/>
            <i className="fa fa-circle fafa" aria-hidden="true"></i>  To provide our chat service <br/>
            <i className="fa fa-circle fafa" aria-hidden="true"></i>  To analyze and optimize the functioning of the Websites and games <br/> 
            <i className="fa fa-circle fafa" aria-hidden="true"></i>  To enable in-game purchases <br/>
            <i className="fa fa-circle fafa" aria-hidden="true"></i>  To provide relevant advertisements and to send you promotional messages by email or other communications in case you have given your consent for this 
            <br/><i className="fa fa-circle fafa" aria-hidden="true"></i>  To enable social network integration, especially with Facebook and Google+ 
            <br/><i className="fa fa-circle fafa" aria-hidden="true"></i>  To provide competitions 
            <br/><i className="fa fa-circle fafa" aria-hidden="true"></i>  To prevent cheating, fraud and crime 
            <br/><i className="fa fa-circle fafa" aria-hidden="true"></i>  To meet legal and tax obligations</p>
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
